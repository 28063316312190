<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7 text-center" style="color: #1A347F;">
          Games
        </div>
      </v-col>
      <!-- <v-col cols="12">
        <v-row no-gutters align="center">
          <v-col cols="7" class="text-left">
            <span class="my-4 fn16-weight7" style="color: #1A347F;">
              Play limits / Day
            </span>
          </v-col>
          <v-col cols="5">
            <v-col
              cols="1"
              class="d-inline-block pa-0 ml-4"
              v-for="(check, index) in subjects.noCheck"
              :key="index"
            >
              <img :src="check.image" width="20" height="20" />
            </v-col>
            <v-col
              cols="1"
              class="d-inline-block pa-0 ml-4"
              v-for="(check, index) in subjects.check"
              :key="index"
            >
              <img :src="check.image" width="20" height="20" />
            </v-col>
          </v-col>
        </v-row>
      </v-col> -->
      <v-col cols="12" class="pa-0">
        <img
          class="mx-auto"
          width="370"
          max-width="370"
          max-height="200"
          src="@/assets/game_pig.png"
          style="position: relative; display: block; margin-left: auto; margin-right: auto;"
          @click="onClickGame()"
        />
      </v-col>
      <!-- <v-btn
        width="360"
        height="180"
        style="position: absolute; top: 120px; display: block; margin-left: auto; margin-right: auto; opacity: 0;"
        @click="gamePig"
      ></v-btn> -->
    </v-row>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      headerstips: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รูปภาพ", value: "headerImg", align: "center" },
        { text: "รายละเอียด", value: "shortDesc", align: "center" },
        { text: "รายละเอียดแบบย่อ", value: "shortDesc", align: "center" },
        { text: "สถานะ", value: "activeFlag", align: "center" },
        { text: "ตัวเลือก", value: "action", align: "center" },
      ],
      items: [],
      subjects: {
        check: [
          { image: require("@/assets/icon/check.png") },
          { image: require("@/assets/icon/check.png") },
          { image: require("@/assets/icon/check.png") },
        ],
        noCheck: [
          { image: require("@/assets/icon/check-white.png") },
          { image: require("@/assets/icon/check-white.png") },
        ],
      },
      search: "",
      count: 0,
      userData: null,
      lineId: ""
    };
  },
  created() {
    // this.getAlltips();
    this.getProfile();
  },
  methods: {
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U1a2cf1f80834b4db16b1f93b7b63cab6`
      );
      console.log("response error", response);

      if (!response) {
        this.$router.push("Unauthorize");
      } else if (!response.data.data.species_type) {
        this.$router.push("ManageSpecies");
      }
      console.log("user Data", response.data.data);
      // console.log("species_type", response.data.data.species_type);
      this.userData = response.data.data;
      
    },
    onClickGame() {
      window.open(
        `http://ec2-18-141-178-166.ap-southeast-1.compute.amazonaws.com/?userId=${this.lineId}`
      );
    },
    async getAlltips() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/tips`
        // 'http://localhost:8081/categories/'
      );
      console.log("getAlltips", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },
    gamePig() {
      this.$router.push("gamePig");
    },
    Viewtips(val) {
      localStorage.setItem("tipsdata", Encode.encode(val));
      this.$router.push("Viewtips");
    },
    async Deletetips(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiData"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/tips/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAlltips();
        }
      });
    },
    goToCreate() {
      this.$router.push("createtips");
    },
  },
};
</script>
